<template>
	<div>
		<CRow>
			<div class="col-md-12">
				<div role="group" class="form-group">
					<label class=""> Note </label><br />
					<span class="h5">{{ rowData.note ? rowData.note : "-" }}</span>
				</div>
			</div>
		</CRow>
		<CRow>
			<div class="col-md-12">
				<div role="group" class="form-group">
					<label class=""> Note System </label><br />
					<span class="h5">
						{{ rowData.noteSystem ? rowData.noteSystem : "-" }}
					</span>
				</div>
			</div>
			<div class="col-md-6">
				<div role="group" class="form-group">
					<label class=""> Batch Number </label><br />
					<span class="h5">{{
						rowData.batchNumber ? rowData.batchNumber : "-"
					}}</span>
				</div>
			</div>
		</CRow>
	</div>
</template>

<script>
export default {
	props: {
		rowData: {
			type: Object,
			required: true,
		},
		rowIndex: {
			type: Number,
		},
	},
};
</script>
